import { ref, watch, computed, onMounted } from "@vue/composition-api";
import Highcharts from "highcharts";
import store from "@/store";
import { formatDate, title } from "@core/utils/filter";
import ExcelJs from "exceljs";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
export default function useUsersList() {
  // Use toast
  const toast = useToast();
  const { t } = useI18nUtils();
  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "username", label: "User Name", sortable: true },
    { key: "mail", label: "Account", sortable: true },
    { key: "coursename", label: "Course Name", sortable: false },
    { key: "coursestatus", label: "Course status", sortable: false },
    { key: "examname", label: "Exam Name", sortable: true },
    { key: "examstatus", label: "Exam Status", sortable: true },
    { key: "examStartDate", label: "Exam Start Date" },
    { key: "userexamid", label: "Detail" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100, 500];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const items = ref();

 const courseStatusData = ref([
  { name: 'Completed', y: 0, color: '#55DD92' },
  { name: 'Unfinished', y: 0, color: '#B4B9BF' }
]);

const examStatusData = ref([
  { name: 'Not Started', y: 0, color: '#B4B9BF' },
  { name: 'Success', y: 0, color: '#55DD92' },
  { name: 'Failed', y: 0, color: '#FF3507FF' }
]);

  const courseChart = ref(null);
  const statusChart = ref(null);

 // 2. METHODS
 const onFiltered = (filteredItems) => {
  totalUsers.value = filteredItems.length;
  const counts = {
    completed: 0,
    unfinished: 0,
    notStarted: 0,
    success: 0,
    failed: 0
  };

  filteredItems.forEach(item => {
    item.coursestatus ? counts.completed++ : counts.unfinished++;
    if (item.examstatus === 200) counts.failed++;
    else if (item.examstatus === 100) counts.success++;
    else counts.notStarted++;
  });

  courseStatusData.value = [
    { name: 'Completed', y: counts.completed, color: '#55DD92' },
    { name: 'Unfinished', y: counts.unfinished, color: '#B4B9BF' }
  ];

  examStatusData.value = [
    { name: 'Not Started', y: counts.notStarted, color: '#B4B9BF' },
    { name: 'Success', y: counts.success, color: '#55DD92' },
    { name: 'Failed', y: counts.failed, color: '#FF3507FF' }
  ];
};
// 3. CHART INITIALIZATION
const initCharts = () => {
  courseChart.value = Highcharts.chart('course-status-chart', {
    chart: { type: 'pie' },
    title: { text: 'Course Status' },
    tooltip: { 
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>' 
    },
    plotOptions: {
      pie: {
        dataLabels: { enabled: true, format: '{point.name}: {point.y}' },
        showInLegend: true
      }
    },
    series: [{
      name: 'Courses',
      data: courseStatusData.value
    }]
  });
     
     statusChart.value = Highcharts.chart('exam-status-chart', {
      colors: ['#B4B9BF', '#55DD92', '#FF3507FF'],
      chart: {
        type: 'pie',
        custom: { label: 'Count of Exams', total: totalUsers.value },
        events: {
          render() {
            const chart = this;
          
            if (chart.customLabel) chart.customLabel.destroy();
     
            chart.customLabel = chart.renderer.label(
              `${chart.options.chart.custom.label}<br/>Total: ${totalUsers.value}`
            )
            .css({
              color: '#000',
              textAnchor: 'middle',
              fontSize: '14px'
            })
            .attr({
              x: chart.plotWidth / 2 + chart.plotLeft,
              y: chart.plotHeight / 2 + chart.plotTop
            })
            .add();
          }
        }
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      title: {
        text: 'Exam Status'
      },
      subtitle: {
        text: 'Members exams status ratio'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{ 
        name: 'Percentage',
        colorByPoint: true,
        innerSize: '75%',
        data: examStatusData.value
      }]
    });
  };
  // 4. WATCHERS
  watch(courseStatusData, (newData) => {
    if (courseChart.value) {
      courseChart.value.series[0].setData(newData);
    }
  }, { deep: true });

  watch(examStatusData, (newData) => {
    if (statusChart.value) {
      statusChart.value.series[0].setData(newData);
    }
  }, { deep: true });
  watch(totalUsers, (newTotal) => {
    if (statusChart.value) {
      statusChart.value.update({
        chart: {
          custom: { 
            label: 'Count of Exams', 
            total: newTotal 
          }
        }
      }, false); 
      statusChart.value.redraw();
    }
  });

  const dataMeta = computed(() => {
    const localItemsCount = items.value ? items.value.length : 0;
    const from =
      perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0);
    return {
      from: from,
      to:
        from + perPage.value > totalUsers.value
          ? totalUsers.value + 1
          : from + perPage.value,
      of: totalUsers.value,
    };
  });

  const fetchCourses = async () => {
    try {
      const response = await store.dispatch("app-members-learning/fetchMemberRecords", {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      });

      const { data } = response.data;
      items.value = data;
      totalUsers.value = data.length;
      onFiltered(data);
      
      return data; 
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching courses list",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      throw error; 
    }
  };
  onMounted(async () => {
    try {
      const loadedItems = await fetchCourses();
      initCharts();
      
      if (loadedItems && loadedItems.length > 0) {
        onFiltered(loadedItems);
      }
    } catch (error) {
      console.error("Initialization failed:", error);
    }
  });
  const refetchData = () => {
    fetchCourses();
  };

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveCourseStatusVariant = (status) => {
    if (status) return "success";
    return "secondary";
  };
  const resolveCourseStatusText = (status) => {
    if (status) return "completed";
    return "unfinished";
  };
  const resolveExamStatusVariant = (status) => {
    if (status === 0 || status === 1 || status === 10) return "secondary";
    if (status === 50) return "warning";
    if (status === 100) return "success";
    if (status === 200) return "danger";
    return "dark";
  };
  const resolveExamStatusText = (status) => {
    if (status === 0 || status === 1 || status === 10) return "Not started";
    if (status === 50) return "Pending for examer check";
    if (status === 100) return "Success";
    if (status === 200) return "Failed";
    return "No exam defined";
  };

  const resolvePageroute = (coursemode) => {
    if (coursemode === "IN-PERSON") return "course-sessions";
    if (coursemode === "ONLINE") return "course-lessons";
    if (coursemode === "HYBRID") return "course-lessons";

    return "course-lessons";
  };
  const resolveCourseContent = (coursemode) => {
    if (coursemode === "IN-PERSON") return "Sessions";
    if (coursemode === "ONLINE") return "Lectures";
    if (coursemode === "HYBRID") return "Lectures";

    return "Lectures";
  };
  const exportListToExcel = async () => {
    const wb = new ExcelJs.Workbook();
    const worksheet = wb.addWorksheet("Members Learning List");
    worksheet.columns = [
      { letter: "A", key: "username", header: "User Name", width: 18 },
      { letter: "B", key: "mail", header: "Account", width: 18 },
      {
        letter: "C",
        key: "coursename",
        header: "Course Name",
        width: 18,
      },
      {
        letter: "D",
        key: "coursestatus",
        header: "Course status",
        width: 15,
      },
      { letter: "E", key: "examname", header: "Exam Name", width: 18 },
      { letter: "F", key: "examstatus", header: "Exam Status", width: 18 },
    ];
    const itemsClone = JSON.parse(JSON.stringify(items.value));

    for (let i in itemsClone) {
      itemsClone[i].coursestatus = resolveCourseStatusText(
        itemsClone[i].coursestatus
      );

      itemsClone[i].examstatus = resolveExamStatusText(
        itemsClone[i].examstatus
      );
    }

    worksheet.addRows(itemsClone);
    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    });
    // Programatically create a link and click it:
    const a = document.createElement("a");
    const date = new Date();
    a.href = URL.createObjectURL(blob);
   
    a.download = `Members Learning List ${formatDate(date)}`;
    a.click();
  };

  return {
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    exportListToExcel,
    items,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveCourseStatusVariant,
    resolveCourseStatusText,
    resolveExamStatusVariant,
    resolveExamStatusText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resolvePageroute,
    resolveCourseContent,
    //CHARTS
    onFiltered,
  };
}

<template>
  <div>
    <b-card>
      <b-row>
        <b-col rows="6">
          <div id="course-status-chart" class="chart-container"></div>
        </b-col>
        <b-col rows="6">
          <div id="exam-status-chart" class="chart-container"></div>
        </b-col>

      </b-row>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="6" md="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("entries") }}</label>
          </b-col>
          <b-col cols="6" md="4">
            <div class="d-inline-block px-1">
              <b-button :variant="showOnlyPendingExams ? 'outline-warning' : 'warning'" @click="
                () => {
                  showOnlyPendingExams = !showOnlyPendingExams;
                  currentPage = 1;
                }
              ">
                {{
                  $t("Exams waiting to be checked: ") + pendingExamItems.count
                }}
              </b-button>
            </div>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="d-inline-block px-1">
              <b-button variant="primary" @click="exportListToExcel()">
                {{ $t("Export to Excel") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table id="my-table" ref="refUserListTable" class="position-relative" :filter="searchQuery" 
        :items="showOnlyPendingExams ? pendingExamItems.data : items" responsive :fields="tableColumns" primary-key="id"
        :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')" :sort-desc.sync="isSortDirDesc"
        :per-page="perPage" @filtered="onFiltered" :current-page="currentPage">
        <template #head()="data">
          <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
        </template>
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }" />
            </template>
            <b-link :to="{ name: 'course-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.courseCode }}</small>
          </b-media>
        </template>
        <!-- Column: Status -->
        <template #cell(coursestatus)="data">
          <b-badge pill :variant="`light-${resolveCourseStatusVariant(
            data.item.coursestatus
          )}`" class="text-capitalize">
            {{ $t(resolveCourseStatusText(data.item.coursestatus)) }}
          </b-badge>
        </template>
        <template #cell(examStartDate)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              formatDate(data.item.examStartDate)
              }}</span>
          </div>
        </template>
        <template #cell(examstatus)="data">
          <b-badge pill :variant="`light-${resolveExamStatusVariant(data.item.examstatus)}`" class="text-capitalize">
            {{ $t(resolveExamStatusText(data.item.examstatus)) }}
          </b-badge>
        </template>
        <template #cell(userexamid)="data">
          <div v-if="data.item.userexamid">
            <b-link :to="{
              name: 'members-learning-detail',
              params: { id: data.item.userexamid },
            }" class="font-weight-bold d-block text-nowrap">
              {{ $t("Exam Result") }}
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }}
              {{ dataMeta.of }}
              {{ $t("entries") }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="showOnlyPendingExams ? pendingExamItems.count : totalUsers
              " :per-page="perPage" first-number last-number aria-controls="my-table" class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import store from "@/store";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./CoursesListFilters.vue";
import useUsersList from "./useMembersLearningList";
import userStoreModule from "../memberLearningStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Chart } from 'highcharts-vue';
export default {
  computed: {},

  methods: {
    formatDate(dateString) {
      if (!dateString) {
        return "";
      }
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );
      return formattedDate.replace(",", "");
    },
    matchesFilter(item) {
      const searchTerm = this.searchQuery.toLowerCase();
      
      // For status filter
      const statusMatch = item.coursestatus
        ? this.getStatusText(item.coursestatus)
          .toLowerCase()
          .includes(searchTerm)
        : false;
      const accountModeMatch = item.mail
        ? item.mail.toLowerCase().includes(searchTerm)
        : false;
      const courseModeMatch = item.username
        ? item.username.toLowerCase().includes(searchTerm)
        : false;

      const courseCodeMatch = item.coursename
        ? item.coursename.toLowerCase().includes(searchTerm)
        : false;
      const titleMatch = item.examname
        ? item.examname.toLowerCase().includes(searchTerm)
        : false;

      return (
        statusMatch ||
        courseModeMatch ||
        titleMatch ||
        courseCodeMatch ||
        accountModeMatch
      );
    },
  },

  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    ToastificationContent,
    Chart
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-members-learning";
    const showOnlyPendingExams = ref(false);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const getPageroute = (coursemode, courseguid) => {
      //console.log(coursemode);
      var routename = "";
      if (coursemode === "IN-PERSON") routename = "course-sessions";
      else if (coursemode === "ONLINE") routename = "course-lectures";
      else if (coursemode === "HYBRID") routename = "course-lectures";
      else routename = "course-lectures";
      var result = { name: routename, params: { id: courseguid } };

      return result;
    };
    const getStatus = (status) => {
      // DRAFT
      if (status == "0") return "secondary";
      // published
      if (status == "100") return "success";

      // ended by time limit
      if (status == "200") return "warning";
      // stopped
      if (status == "500") return "warning";

      return "secondary";
    };
    const getStatusText = (status) => {
      // DRAFT
      if (status == "0") return "Draft";
      // published
      if (status == "100") return "Published";
      // ended by time limit
      if (status == "200") return "Ended";
      // stopped
      if (status == "500") return "Stopped";

      return "Draft";
    };

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      items,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveCourseStatusVariant,
      resolveCourseStatusText,
      resolveExamStatusVariant,
      resolveExamStatusText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      exportListToExcel,
      resolveCourseContent,
      onFiltered,
    } = useUsersList();

    const deleteCourse = (coursedata) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result) {
          store
            .dispatch("app-course/deleteCourse", {
              id: coursedata.courseguid,
            })
            .then((e) => {
              fetchCourses();
            });
        }
      });
    };
    const pendingExamItems = computed(() => {
      const pendingItems = items.value?.filter((e) => e.examstatus == 50) ?? [];
      return { count: pendingItems.length, data: pendingItems };
    });

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      items,
      pendingExamItems,
      exportListToExcel,
      // Filter
      avatarText,
      showOnlyPendingExams,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveCourseStatusVariant,
      resolveCourseStatusText,

      resolveExamStatusVariant,
      resolveExamStatusText,
      roleOptions,
      planOptions,
      statusOptions,
      deleteCourse,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      getPageroute,
      getStatus,
      getStatusText,
 
      onFiltered,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
